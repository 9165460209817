import React, {useState, useEffect} from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import api from '../api';
import './style/Login.css';

const Login = ({setIsAuthenticated, setIsAdmin}) => {

    const [rememberMe, setRememberMe] = useState(false);
    const [enrollVisible, setEnrollVisible] = useState(false);
    const [resetSentVis, setResetSentVis] = useState(false);
    const [resetVisible, setResetVisible] = useState(false);
    const [loginError, setLoginError] = useState(null);
    const [resetError, setResetError] = useState('');
    const [registerErrorVisible, setRegisterErrorVisible] = useState(false);
    const [existingUserErrorVisible, setExistingUserVisible] = useState(false);
    const [registerSuccessVisible, setRegisterSuccessVisible] = useState(false);
    const [resetEmailAddress, setResetEmailAddress] = useState('');

    useEffect(() => {
        if (sessionStorage.getItem('sessionExpired') === 'true') {
            sessionStorage.clear();
            window.location.reload();
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validate: ({ username, password }) => {
            const errors = {};
    
            if (!username) {
                errors.username = 'Please enter a username';
            }
    
            if (!password) {
                errors.password = 'Please enter a password';
            }
    
            return errors;
        },
        onSubmit: (values) => {
            handleSubmitLogin(values);
        }
    });

    const handleSubmitLogin = async (values) => {

        const formattedValues = formatFormikValuesLogin(values);
        try {
            const response = await api.post('/users/login', formattedValues);
            const newToken = response.data.access_token;
            sessionStorage.clear();
            sessionStorage.setItem('userID', response.data.user.id);
            sessionStorage.setItem('token', newToken);
            sessionStorage.setItem('projectSelected', false);
            sessionStorage.setItem('currentScreen', 'Home');
            setIsAuthenticated(true);
            if (response.data.user.admin) {
                setIsAdmin(true)
            }
            } catch (error) {
            if (error.code === 'ERR_NETWORK') {
                setLoginError('Error - Check Network Connection')
            }
            else if (error.response.status === 400 || error.response.status === 500) {
                setLoginError('Invalid Login');
            }
        }
    };

    const formatFormikValuesLogin = (formikValues) => {
        return {
            "email": formikValues.username,
            "password": formikValues.password
        };
    };

    const formik2 = useFormik({
        initialValues: {
            firstnameSignup: '',
            lastnameSignup: '',
            emailSignup: '',
            newpassSignup: '',
            connewpassSignup: ''
        },
        validate: ({ firstnameSignup, lastnameSignup, emailSignup, newpassSignup, connewpassSignup }) => {
            const errors = {};
    
            if (!firstnameSignup) {
                errors.firstnameSignup = 'Please enter your first name';
            }
    
            if (!lastnameSignup) {
                errors.lastnameSignup = 'Please enter your last name';
            }
    
            if (!emailSignup) {
                errors.emailSignup = 'Please enter your email address';
            } else if (!emailSignup.includes('trccompanies.com') && !emailSignup.includes('trcsolutions.com')) {
                errors.emailSignup = 'Please use a trc email address';
            }
    
            if (!newpassSignup) {
                errors.newpassSignup = 'Please enter a password';
            }
    
            if (!connewpassSignup) {
                errors.connewpassSignup = 'Please confirm your password';
            } else if (connewpassSignup !== newpassSignup) {
                errors.connewpassSignup = 'Passwords do not match';
            }
    
            return errors;
        },
        onSubmit: (values) => {
            handleSubmitRegistration(values);
        }
    });

    const handleSubmitRegistration = async (values) => {
        const formattedValues = formatFormikValues(values);
        try {
            await api.post('/users/register', formattedValues);
            setEnrollVisible(false);
            setRegisterSuccessVisible(true);
        }
        catch (error) {
            if (error.code === 'ERR_NETWORK') {
                setEnrollVisible(false);
                setRegisterErrorVisible(true);
            }
            else if (error.response.status === 400) {
                setEnrollVisible(false);
                setExistingUserVisible(true);
            }
        }
    };

    const formatFormikValues = (formikValues) => {
        return {
            "first_name": formikValues.firstnameSignup,
            "last_name": formikValues.lastnameSignup,
            "email": formikValues.emailSignup,
            "passwordDigest": formikValues.newpassSignup,
            "role_id": 1
        };
    };

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const isFormFieldInvalidReg = (name) => !!(formik2.touched[name] && formik2.errors[name]);

    const getFormErrorMessageReg = (name) => {
        return isFormFieldInvalidReg(name) ? <small className="p-error">{formik2.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const sendResetEmail = async () => {
        try {
            if (resetEmailAddress === '') {
                setResetError('Please enter an email address')
            }
            else if (!resetEmailAddress.includes('trccompanies.com') && !resetEmailAddress.includes('trcsolutions.com')) {
                setResetError('Make sure the email format is correct')
            }
            else {
                await api.post(`/users/send-reset-email/${resetEmailAddress}`, null, null);
                console.log('got here')
                setResetEmailAddress('');
                setResetVisible(false);
                setResetSentVis(true);
            }
        } 
        catch (error) {
            if (error.code === "ERR_NETWORK") {
                setResetError('Error - Check network connection')
            }
            else {
                if (error.response.status === 500) {
                    setResetError('Error - Issue with API');
                }
                else if (error.response.status === 403) {
                    setResetError('Error - User not found');
                }
            }
        }
    };

    return (
        <div id='login-form'>
            <div id='login-title'>
                <h1 style={{ fontSize: '40px' }}>MDI - Login</h1>
                <p className='login-text'>Please enter your credentials.</p>
            </div>
            {<p className="p-error">{loginError}</p>}
            <form onSubmit={formik.handleSubmit} className="flex flex-column align-items-center gap-3">
                <div className="flex flex-column gap-2">
                    <div className='login-label'>
                        <label htmlFor="username">Email Address</label>
                        {getFormErrorMessage('username')}
                    </div>
                    <InputText id="username" name="username" checked={formik.values.username} onChange={(e) => {formik.setFieldValue('username', e.target.value)}} style={{ width: '40vw' }} aria-describedby="username-help" />
                </div>
                <div className="flex flex-column gap-2">
                    <div className='login-label'>
                        <label htmlFor="password">Password</label>
                        {getFormErrorMessage('password')}
                    </div>
                    <Password id="password" name="password" feedback={false} checked={formik.values.password} onChange={(e) => {formik.setFieldValue('password', e.target.value)}} aria-describedby="password-help" />
                </div>
                
                <div id='below-password'>
                    <div id='remember-group'>
                        <div className="flex align-items-center">
                            <Checkbox inputId="remember" name="remember" value={rememberMe} onChange={() => {setRememberMe(!rememberMe)}} checked={rememberMe}/>
                            <label htmlFor="remember" className="ml-2">Remember me</label>
                        </div>
                        <a className='login-link' onClick={() => setResetVisible(true)}>Reset my password</a>
                    </div>
                    <Button id='sign-in-button' style={{ width: '40vw' }} label="Sign In" type="submit"/>
                </div>
            </form>
            <div id='enroll-group'>
                <p className='login-text'>Don't have an account?</p>
                <a className='login-link' onClick={() => setEnrollVisible(true)}>Enroll as a new user</a>
                <Dialog header="Create an Account" visible={enrollVisible} style={{ width: '35vw' }} onHide={() => setEnrollVisible(false)}>
                    <form onSubmit={formik2.handleSubmit} className="flex flex-column align-items-center gap-3 update-sect">
                        <div className="flex flex-column gap-2" style={{ width: '100%' }}>
                            <div className='login-label'>
                                <label htmlFor="firstnameSignup">First Name</label>
                                {getFormErrorMessageReg('firstnameSignup')}
                            </div>
                            <InputText id="firstnameSignup" name="firstnameSignup" checked={formik2.values.firstnameSignup} onChange={(e) => {formik2.setFieldValue('firstnameSignup', e.target.value)}}/>
                        </div>
                        <div className="flex flex-column gap-2" style={{ width: '100%' }}>
                            <div className='login-label'>
                                <label htmlFor="lastnameSignup">Last Name</label>
                                {getFormErrorMessageReg('lastnameSignup')}
                            </div>
                            <InputText id="lastnameSignup" name="lastnameSignup" checked={formik2.values.lastnameSignup} onChange={(e) => {formik2.setFieldValue('lastnameSignup', e.target.value)}} />
                        </div>
                        <div className="flex flex-column gap-2" style={{ width: '100%' }}>
                            <div className='login-label'>
                                <label htmlFor="emailSignup">Email Address</label>
                                {getFormErrorMessageReg('emailSignup')}
                            </div>
                            <InputText id="emailSignup" name="emailSignup" checked={formik2.values.emailSignup} onChange={(e) => {formik2.setFieldValue('emailSignup', e.target.value)}} />
                        </div>
                        <div className="flex flex-column gap-2" style={{ width: '100%' }}>
                            <div className='login-label'>
                                <label htmlFor="newpassSignup">Create Password</label>
                                {getFormErrorMessageReg('newpassSignup')}
                            </div>
                            <Password id="newpassSignup" name="newpassSignup" checked={formik2.values.newpassSignup} onChange={(e) => {formik2.setFieldValue('newpassSignup', e.target.value)}} />
                        </div>
                        <div className="flex flex-column gap-2" style={{ width: '100%' }}>
                            <div className='login-label'>
                                <label htmlFor="connewpassSignup">Confirm Password</label>
                                {getFormErrorMessageReg('connewpassSignup')}
                            </div>
                            <Password id="connewpassSignup" name="connewpassSignup" checked={formik2.values.connewpassSignup} onChange={(e) => {formik2.setFieldValue('connewpassSignup', e.target.value)}} />
                        </div>
                        <Button label="Sign Up" type="submit" style={{ width: '100%' }}/>
                    </form>
                </Dialog>
                <Dialog header="Error Creating a New Account" visible={registerErrorVisible} style={{ width: '25vw' }} onHide={() => setRegisterErrorVisible(false)}>
                    <p className="m-0">
                        Error creating a new account. Please check your internet connection. If issues persist, please create a ticket in the help screen.
                    </p>
                </Dialog>
                <Dialog header="User already exists" visible={existingUserErrorVisible} style={{ width: '25vw' }} onHide={() => setExistingUserVisible(false)}>
                    <p className="m-0">
                        Looks like there is already an account registered with this email. Click "Reset my password" to generate a new password.
                    </p>
                </Dialog>
                <Dialog header="Success - New Account Created!" visible={registerSuccessVisible} style={{ width: '50vw' }} onHide={() => setRegisterSuccessVisible(false)}>
                    <p className="m-0">
                        Your new account has been successfully created. You can now login to the application. To get access to an active project, please reach out to a manager or team leader to request access.
                    </p>
                </Dialog>
                <Dialog header="Forgot your Password?" visible={resetVisible} style={{ width: '35vw' }} onHide={() => {setResetVisible(false);setResetError('')}}>
                    <div className='update-sect'>
                        <div className="flex flex-column gap-2">
                            <label htmlFor="username">Enter your email address then click the "Submit" button to send a password reset email</label>
                            <p style={{margin:'0px'}} className="p-error">{resetError}</p>
                            <InputText id="emailaddress" value={resetEmailAddress} onChange={(e) => setResetEmailAddress(e.target.value)}/>
                        </div>
                        <Button label="Submit" onClick={() => sendResetEmail()}/>
                    </div>
                </Dialog>
                <Dialog header="Reset email sent!" visible={resetSentVis} style={{ width: '50vw' }} onHide={() => setResetSentVis(false)}>
                    <p className="m-0">
                        If you have an account registered under this email address, please check your email inbox for a password reset message.
                    </p>
                </Dialog>
            </div>
        </div>
    )
};

export default Login;