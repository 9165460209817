import React, { useState, useEffect, useRef } from 'react';
import DocumentCard from '../components/DocumentCard';
import { Button } from 'primereact/button';
import { useSelectedContext } from '../components/SelectedContext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import { Card } from 'primereact/card';
import { Skeleton } from 'primereact/skeleton';
import './style/Overview.css';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import DocumentCardSkeleton from '../components/DocumentCardSkeleton';
import api from '../api';


const Overview = () => {

  const { updateSelectedView, setProjectSelected, closed, setClosed, showExtractProgress, setFCSwitch, fcSwitch } = useSelectedContext();
  const [visible, setVisible] = useState(false);
  const [createVisible, setCreateVisible] = useState(false);
  const [createClientVisible, setCreateClientVisible] = useState(false);
  const [newProject, setNewProjectValue] = useState('');
  const [selectedClient2, setSelectedClient2] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [projectDisabled, setProjectDisabled] = useState(true);
  const [clients, setClients] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [cardItems, setCardItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [projectLoading, setProjectLoading] = useState(false);
  const [clientSwitch, setClientSwitch] = useState(true);
  const toastBottomLeft = useRef(null);

  const showMessage = (ref, severity, message) => {
    ref.current.show({ severity: severity, summary: message, life: 3000 });
  };

  useEffect(() => {
    updateSelectedView('Source Documents');
    const token = sessionStorage.getItem('token');
    if (token && clients.length === 0) {
      getClients();
    }
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem('projectSelected') === 'true') {
      getCardItems(parseInt(sessionStorage.getItem('selectedProjectId'),10));
    }
    else {
      setVisible(true)
    }
  }, [sessionStorage.getItem('selectedProject'), showExtractProgress]);

  const getClients = async () => {
    try {
        const headers = {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        };
        const response = await api.get('/clients/', { headers });
        const transformedData = response.data.map(client => ({ name: client.client_name, id: client.id }));
        setClients(transformedData);
        const responseAll = await api.get('/clients/all-clients', { headers });
        const transformedDataAll = responseAll.data.map(client => ({ name: client.client_name, id: client.id }));
        setAllClients(transformedDataAll);
    } catch (error) {
        console.error('Failed to get clients: ', error);
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem('projectSelected') !== 'true' && selectedClient){
      getProjects();
    }
  }, [clientSwitch]);

  const getProjects = async () => {
    try {
        const headers = {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        };
        const response = await api.get(`/projects/get-access-projects/${selectedClient.id}`, { headers });
        const selected_client_id = selectedClient?.id || sessionStorage.getItem('selectedClientId');
        const transformedData = response.data
          .filter(project => project.client_id === selected_client_id)
          .map(project => ({ name: project.project_name, id: project.id }));
        setProjects(transformedData);
    } catch (error) {
        console.error('Failed to get projects: ', error);
    }
      finally {
        setProjectLoading(false);
      }
  };

  const getCardItems = async (project_id) => {
    try {
      setLoading(true);
      const token = sessionStorage.getItem('token');
      const headers = {
        Authorization: `Bearer ${token}`
      };
      const overviewTotal = await api.get(`/projects/get-overview-total/${project_id}`, {headers})
      const overviewLast = await api.get(`/projects/get-overview-last/${project_id}`, {headers})
      setCardItems(getFormattedOverview(overviewTotal.data, overviewLast.data))
    } catch (error) {
      console.error('Failed to get card items: ', error);
    }
      finally {
        setLoading(false);
      }
  }

  const getFormattedOverview = (overviewTotal, overviewLast) => {
    const lastItem = Object.values(overviewLast).pop() || null;
    const overviewItems = Object.keys(overviewTotal).map(keyTotal => {
      const { count: docTotal, doc_type_id: docType } = overviewTotal[keyTotal];
  
      return {
        label: keyTotal,
        Total_Files: docTotal,
        Last_Upload: lastItem,
        docType
      };
    });
  
    return overviewItems;
  };

  const formik = useFormik({
    initialValues: {
        clientSelect: '',
        projectSelect: ''
    },
    validate: (data) => {
        let errors = {};

        if (!data.clientSelect) {
          errors.clientSelect = 'Please select a client';
        }
    
        if (!data.projectSelect) {
          errors.projectSelect = 'Please select a project';
        }

        return errors;
    },
    onSubmit: () => {
        formik.resetForm();
        setVisible(false);
        setProjectSelected(true);
        setClosed(false);
        sessionStorage.setItem('closed', true)
        sessionStorage.setItem('projectSelected', true)
        sessionStorage.setItem('selectedProject', selectedProject.name)
        sessionStorage.setItem('selectedProjectId', selectedProject.id)
        sessionStorage.setItem('selectedClient', selectedClient.name)
        sessionStorage.setItem('selectedClientId', selectedClient.id)
        setProjectDisabled(true)
    }
  });

  const formik2 = useFormik({
    initialValues: {
      clientSelect2: '',
      projectName: ''
    },
    validate: (data) => {
      const errors = {};
  
      if (!data.clientSelect2) {
        errors.clientSelect2 = 'Please select a client';
      }
  
      if (!data.projectName) {
        errors.projectName = 'Please name your new project';
      }
  
      return errors;
    },
    onSubmit: (values) => {
      handleSubmitNewProject(values);
    }
  });

  const handleSubmitNewProject = async (values) => {

    const formattedValues = await formatFormikValuesProject(values);
    try {
        const token = sessionStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`
        };  
        const response = await api.post('/projects/add-project', formattedValues, {headers});
        const newPAInput = {
          "user_id": response.data.user_id,
          "project_id": response.data.id,
        }
        await api.post('/project-access/add-permission', newPAInput, {headers});
        formik2.resetForm();
        setCreateVisible(false);
        setSelectedClient(selectedClient2);
        setSelectedProject({ name: newProject });
        setProjectSelected(true)
        sessionStorage.setItem('selectedProject', newProject)
        sessionStorage.setItem('selectedClient', selectedClient2.name)
        sessionStorage.setItem('projectSelected', true)
        sessionStorage.setItem('selectedProjectId', response.data.id)
        setFCSwitch(!fcSwitch)
    } catch (error) {
        if (error.response.data.detail === 'project with that name already exists'){
          showMessage(toastBottomLeft, 'error', 'Project already exists');
        }
        else {
          showMessage(toastBottomLeft, 'error', 'Error');
        }
    }
  };

  const formatFormikValuesProject = async (formikValues) => {
    try {
        return {
          "project_name": formikValues.projectName,
          "client_id": formikValues.clientSelect2.id,
          "user_id": parseInt(sessionStorage.getItem("userID"), 10)
        };
    } catch (error) {
        console.error('Failed to get current user:', error);
    }
  };

  const formik3 = useFormik({
    initialValues: {
      clientName: ''
    },
    validate: (data) => {
      const errors = {};
  
      if (!data.clientName.trim()) {
        errors.clientName = 'Please name your new client!';
      }
  
      return errors;
    },
    onSubmit: (values) => {
      handleSubmitNewClient(values);
    }
  });
  

  const handleSubmitNewClient = async (values) => {

    const formattedValues = formatFormikValuesClient(values);
    try {
        await api.post('/clients/add-client', formattedValues);
        setCreateClientVisible(false)
        getClients()
        showMessage(toastBottomLeft, 'success', 'Client Created');
        formik3.resetForm();
    } catch (error) {
        console.error('New Client Creation Failed:', error);
        if (error.response.data.detail === 'Client already exists'){
          showMessage(toastBottomLeft, 'error', 'Client already exists');
        }
        else {
          showMessage(toastBottomLeft, 'error', 'Error');
        }
    }
  };

  const formatFormikValuesClient = (formikValues) => {
      return {
          "client_name": formikValues.clientName,
      };
  };

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const isFormFieldInvalid2 = (name) => !!(formik2.touched[name] && formik2.errors[name]);
  const isFormFieldInvalid3 = (name) => !!(formik3.touched[name] && formik3.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };
  const getFormErrorMessage2 = (name) => {
    return isFormFieldInvalid2(name) ? <small className="p-error">{formik2.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };
  const getFormErrorMessage3 = (name) => {
    return isFormFieldInvalid3(name) ? <small className="p-error">{formik3.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };

  const resetForms = () => {
    setSelectedClient(null);
    setSelectedProject(false);
    setNewProjectValue('');
    setSelectedClient2(null);
    formik.resetForm();
    formik2.resetForm();
    formik3.resetForm();
  }

  const resetOverview = () => {
    sessionStorage.setItem('selectedProject', null);
    sessionStorage.setItem('selectedClient', null);
    sessionStorage.setItem('projectSelected', false);
    setVisible(true);
    setProjectSelected(false);
    setProjectDisabled(true);
    setSelectedClient(null);
    setSelectedProject(null);
    setClosed(false);
    setLoading(true);
  }

  const exitProjectSelect = () => {
    setVisible(false);
    setClosed(true);
  }

  const headerElementSelect = (
    <div className="select-header">
        <p style={{margin:'0px'}}>Please Select a Client and a Project</p>
        { projectLoading && <i className="pi pi-spin pi-spinner" style={{ fontSize: '1.5rem' }}></i> }
    </div>
  );

  return (
      <div id='Overview'>
          <div>
            <h1 className='fadein animation-duration-1000 animation-iteration-1'>Overview</h1>
          </div>
          <Card id='proj-client-card' style={{ marginTop: '18px' }}>
            { sessionStorage.getItem('projectSelected') && sessionStorage.getItem('projectSelected') !== 'false' ? 
              <div id='proj-select-items'>
                <div id='current-proj-selection'>
                  <div className='selected-item'>
                    <p className='sc-text sc-text-header'>Selected Client:</p>
                    <p className='sc-text'>{sessionStorage.getItem('selectedClient')}</p>
                  </div>
                  <div className='selected-item'>
                    <p className='sc-text sc-text-header'>Selected Project:</p>
                    <p className='sc-text'>{sessionStorage.getItem('selectedProject')}</p>
                  </div>
                  <Button label="Select a New Client/Project" onClick={() => resetOverview()}/>
                </div>
                <div className='create-buttons'>
                  <Button label="Create a New Client" outlined onClick={() => {setNewProjectValue('');setSelectedClient2(null);setCreateClientVisible(true);setVisible(false);}}/>
                  <Button label="Create a New Project" outlined onClick={() => {setNewProjectValue('');setSelectedClient2(null);setCreateVisible(true);setVisible(false)}} />
                </div>
              </div> : closed ?
              <div id='proj-select-items'>
                <div id='current-proj-selection'>
                  <div className='selected-item'>
                    <p className='sc-text sc-text-header'>No Client/Project Selected</p>
                  </div>
                  <Button label="Select a New Client/Project" onClick={() => resetOverview()}/>
                </div>
                <div className='create-buttons'>
                  <Button label="Create a New Client" outlined onClick={() => {setNewProjectValue('');setSelectedClient2(null);setCreateClientVisible(true);setVisible(false);}}/>
                  <Button label="Create a New Project" outlined onClick={() => {setNewProjectValue('');setSelectedClient2(null);setCreateVisible(true);setVisible(false)}} />
                </div>
              </div>
              :
              <div id='proj-select-items'>
                <div id='current-proj-selection'>
                  <div className='selected-item'>
                    <Skeleton height='25px' className="mb-2"></Skeleton>
                    <Skeleton height='15px' className="mb-2"></Skeleton>
                  </div>
                  <div className='selected-item'>
                    <Skeleton height='25px' className="mb-2"></Skeleton>
                    <Skeleton height='15px' className="mb-2"></Skeleton>
                  </div>
                  <Button label="Select a New Client/Project"/>
                </div>
                <div className='create-buttons'>
                  <Button label="Create a New Client" outlined onClick={() => {resetForms();setCreateClientVisible(true);setVisible(false);}}/>
                  <Button label="Create a New Project" outlined onClick={() => {resetForms();setCreateVisible(true);setVisible(false);}}/>
                </div>
              </div>
            }
          </Card>
          {sessionStorage.getItem('projectSelected') && sessionStorage.getItem('projectSelected') !== 'false' && (
            <div id='docCards'>
              {loading ? (
                <div id='skel-doc-list'>
                  {[...Array(8)].map((_, index) => (
                    <DocumentCardSkeleton key={index} />
                  ))}
                </div>
              ) : (
                cardItems.length > 0 ? (
                  cardItems.map((card, index) => (
                    <div className='doc-flex-item docCardItem flip animation-duration-500 animation-iteration-1' style={{ animationDelay: `${(index + 4) * 100}ms` }} key={index}>
                      <DocumentCard title={card.label} total={card.Total_Files} lastUpload={card.Last_Upload} docType={card.docType} />
                    </div>
                  ))
                ) : (
                  <div className='doc-flex-item docCardItem flip animation-duration-500 animation-iteration-1'>
                    <Card title="No Documents">
                      <p className="m-0" id='no-doc-text'>
                          Import documents to begin extracting attributes.
                      </p>  
                    </Card>
                  </div>
                )
              )}
            </div>
          )}
          <Dialog header={headerElementSelect} focusOnShow={false} draggable visible={visible} style={{ width: '30vw' }} onHide={() => {exitProjectSelect()}}>
            <div id='project-selection-items'>
              <form onSubmit={formik.handleSubmit} id='project-selection'>
                <div className='proj-select-form-item' style={{ flexBasis: '40%' }}>
                  {getFormErrorMessage('clientSelect')}
                  <Dropdown value={selectedClient} onChange={(e) => {setSelectedClient(e.target.value);formik.setFieldValue('clientSelect', e.value.name);setProjectDisabled(false);setClientSwitch(!clientSwitch);setProjectLoading(true)}} checked={formik.values.clientSelect} 
                    id='clientSelect' name='clientSelect' options={clients} optionLabel="name" 
                    placeholder="Select a Client" />
                </div>
                <div className='proj-select-form-item' style={{ flexBasis: '40%' }}>
                  {getFormErrorMessage('projectSelect')}
                  <Dropdown value={selectedProject} onChange={(e) => {setSelectedProject(e.target.value);formik.setFieldValue('projectSelect', e.target.value)}} checked={formik.values.projectSelect} 
                    id='projectSelect' name='projectSelect' options={projects} optionLabel="name" 
                    placeholder="Select a Project" disabled={projectDisabled || projectLoading}/>
                </div>
                <div className='proj-select-form-item'>
                  {getFormErrorMessage('placeHolder')}
                  <Button label="Select" type="submit"/>
                </div>
              </form>
            </div>
          </Dialog>
          <Dialog header="Create a New Project" draggable visible={createVisible} style={{ width: '30vw' }} onHide={() => {setCreateVisible(false);setClosed(true)}}>
              <form onSubmit={formik2.handleSubmit} id='project-creation'>
                <div className='proj-select-form-item' style={{ flexBasis: '40%' }}>
                  {getFormErrorMessage2('clientSelect2')}
                  <Dropdown value={selectedClient2} onChange={(e) => {setSelectedClient2(e.value);formik2.setFieldValue('clientSelect2', e.value)}} checked={formik2.values.clientSelect2} 
                    id='clientSelect2' name='clientSelect2' options={allClients} optionLabel="name" 
                    placeholder="Select a Client" />
                </div>
                <div className='proj-select-form-item' style={{ flexBasis: '40%' }}>
                  {getFormErrorMessage2('projectName')}
                  <InputText value={newProject} onChange={(e) => {formik2.setFieldValue('projectName', e.target.value);setNewProjectValue(e.target.value)}} checked={formik2.values.projectName} 
                    id='projectName' name='projectName'
                    placeholder="Project Name" />
                </div>
                <div className='proj-select-form-item'>
                  {getFormErrorMessage2('placeHolder2')}
                  <Button label="Create" type="submit"/>
                </div>
              </form>
          </Dialog>
          <Dialog header="Create a New Client" draggable visible={createClientVisible} style={{ width: '30vw' }} onHide={() => {setCreateClientVisible(false);setClosed(true)}}>
              <form onSubmit={formik3.handleSubmit} id='client-creation'>
                <div className='proj-select-form-item' style={{ flexBasis: '40%' }}>
                  {getFormErrorMessage3('clientName')}
                  <InputText onChange={(e) => {formik3.setFieldValue('clientName', e.target.value)}} checked={formik3.values.clientName} 
                    id='clientName' name='clientName'
                    placeholder="Client Name" />
                </div>
                <div className='proj-select-form-item'>
                  {getFormErrorMessage3('placeHolder2')}
                  <Button label="Create" type="submit"/>
                </div>
              </form>
          </Dialog>
          <Toast ref={toastBottomLeft} position="bottom-left" />
      </div>
  )
};

export default Overview;
